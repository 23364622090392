import { UserTypes } from './permissionContants';
export const mainMenu = [
  {
    title: 'Dashboard',
    to: 'Dashboard',
    resource: 'ingreso',
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.ACCOUNTING_EMPLOYEE,
      UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
    ],
  },
  {
    title: 'Dashboard',
    to: 'Dashboard Docentes',
    resource: 'alumno',
    show: [
      UserTypes.SCHOOLCONTROL_EMPLOYEE,
      UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
      UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
      UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
      UserTypes.KINDER_COORDINATOR_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
      UserTypes.TEACHER_EMPLOYEE,
    ],
  },
  {
    title: 'Dashboard',
    to: 'Dashboard Tutores',
    resource: 'alumno',
    show: [UserTypes.TUTOR],
  },
  {
    title: 'Alumnos',
    to: 'Alumnos',
    resource: 'alumno',
    children: [
      {
        title: 'Alumnos',
        to: 'Alumnos',
        resource: 'alumno',
        show: [UserTypes.TUTOR],
      },
      {
        title: 'Estimulación temprana',
        to: 'Estimulacion Temprana',
        resource: 'alumno',
        show: [UserTypes.TUTOR],
      },
    ],
    show: [UserTypes.TUTOR],
  },
  {
    title: 'Gestión de alumnos',
    to: 'Alumnos',
    resource: 'alumno',
    children: [
      {
        title: 'Todos los alumnos',
        to: 'Alumnos',
        resource: 'alumno',
        show: [UserTypes.TEACHER_EMPLOYEE],
      },
      {
        title: 'Estimulación temprana',
        to: 'Estimulacion Temprana',
        resource: 'alumno',
        show: [UserTypes.TEACHER_EMPLOYEE],
      },
      {
        title: 'Grupos oficiales',
        to: 'Grupos',
        resource: 'grupo',
        show: [UserTypes.TEACHER_EMPLOYEE],
      },
      // {
      //   title: 'Clubes',
      //   to: 'Clubes',
      //   resource: 'club',
      //   show: [UserTypes.TEACHER_EMPLOYEE],
      // },
    ],
    show: [UserTypes.TEACHER_EMPLOYEE],
  },
  {
    title: 'Finanzas',
    to: 'Ingresos',
    resource: 'ingreso',
    children: [
      {
        title: 'Ingresos',
        to: 'Ingresos',
        resource: 'ingreso',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      {
        title: 'Egresos',
        to: 'Egresos',
        resource: 'egreso',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      {
        title: 'Asignación de colegiaturas',
        to: 'Adeudos',
        resource: 'adeudo',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      // {
      //   title: 'Adeudos',
      //   to: 'Adeudos',
      //   resource: 'adeudo',
      //   show: [
      //     UserTypes.SUPERADMIN,
      //     UserTypes.APP_ADMIN_EMPLOYEE,
      //     UserTypes.ACCOUNTING_EMPLOYEE,
      //     UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
      //     UserTypes.COLLECTION_EMPLOYEE,
      //   ],
      // },
      // {
      //   title: 'Reportes',
      //   to: 'Reportes',
      //   resource: 'ingreso',
      //   show: [
      //     UserTypes.SUPERADMIN,
      //     UserTypes.APP_ADMIN_EMPLOYEE,
      //     UserTypes.ACCOUNTING_EMPLOYEE,
      //     UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
      //     UserTypes.COLLECTION_EMPLOYEE,
      //   ],
      // },
    ],
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.APP_ADMIN_EMPLOYEE,
      UserTypes.ACCOUNTING_EMPLOYEE,
      UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
      UserTypes.COLLECTION_EMPLOYEE,
    ],
  },
  {
    title: 'Control escolar',
    to: 'Alumnos',
    resource: 'alumno',
    children: [
      {
        title: 'Alumnos',
        to: 'Alumnos',
        resource: 'alumno',
        show: [
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
        ],
      },
      {
        title: 'Planes de estudio',
        to: 'Planes de estudio',
        resource: 'planestudios',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
        ],
      },
      {
        title: 'Acciones de grupos',
        to: 'Acciones Grupos',
        resource: 'cambiogrupo',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
        ],
      },
      {
        title: 'Calificaciones',
        to: 'Calificaciones',
        resource: 'calificacion',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
          UserTypes.TEACHER_EMPLOYEE,
        ],
      },
      {
        title: 'Autorización de calificaciones',
        to: 'Autorizar Calificaciones',
        resource: 'calificacion',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
        ],
      },
      // {
      //   title: 'Boletas',
      //   to: 'Boletas',
      //   resource: 'boleta',
      //   show: [
      //     UserTypes.SUPERADMIN,
      //     UserTypes.APP_ADMIN_EMPLOYEE,
      //     UserTypes.SCHOOLCONTROL_EMPLOYEE,
      //   ],
      // },
      {
        title: 'Materias asignadas',
        to: 'Materias',
        resource: 'materia',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
          UserTypes.TEACHER_EMPLOYEE,
        ],
      },
      {
        title: 'Profesores',
        to: 'Profesores',
        resource: 'empleado',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
        ],
      },
      {
        title: 'Grupos oficiales',
        to: 'Grupos',
        resource: 'grupo',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
        ],
      },
      {
        title: 'Grupos especiales',
        to: 'Grupos Especiales',
        resource: 'grupo',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
        ],
      },
      {
        title: 'Estimulación temprana',
        to: 'Estimulacion Temprana',
        resource: 'alumno',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
        ],
      },
      // {
      //   title: 'Clubes',
      //   to: 'Clubes',
      //   resource: 'club',
      //   show: [
      //     UserTypes.SUPERADMIN,
      //     UserTypes.APP_ADMIN_EMPLOYEE,
      //     UserTypes.SCHOOLCONTROL_EMPLOYEE,
      //   ],
      // },
      {
        title: 'Reportes',
        to: 'Control Escolar Reportes',
        resource: 'alumno',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
        ],
      },
    ],
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.APP_ADMIN_EMPLOYEE,
      UserTypes.SCHOOLCONTROL_EMPLOYEE,
      UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
      UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
      UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
      UserTypes.KINDER_COORDINATOR_EMPLOYEE,
      UserTypes.TEACHER_EMPLOYEE,
    ],
  },
  {
    title: 'Administración',
    to: 'Alumnos',
    resource: 'alumno',
    children: [
      {
        title: 'Alumnos',
        to: 'Alumnos',
        resource: 'alumno',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      {
        title: 'Empleados',
        to: 'Empleados',
        resource: 'empleado',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
        ],
      },
      {
        title: 'Proveedores',
        to: 'Proveedores',
        resource: 'proveedor',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
        ],
      },
      {
        title: 'Anuncios',
        to: 'Anuncios',
        resource: 'anuncios',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
          UserTypes.TEACHER_EMPLOYEE,
        ],
      },
      {
        title: 'Productos y servicios',
        to: 'Productos',
        resource: 'product',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
        ],
      },
    ],
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.APP_ADMIN_EMPLOYEE,
      UserTypes.SCHOOLCONTROL_EMPLOYEE,
      UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
      UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
      UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
      UserTypes.KINDER_COORDINATOR_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
      UserTypes.ACCOUNTING_EMPLOYEE,
      UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
      UserTypes.TEACHER_EMPLOYEE,
      UserTypes.COLLECTION_EMPLOYEE,
    ],
  },
];

export const mainMenuWithoutSchoolControl = [
  {
    title: 'Dashboard',
    to: 'Dashboard',
    resource: 'ingreso',
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.ACCOUNTING_EMPLOYEE,
      UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
    ],
  },
  {
    title: 'Dashboard',
    to: 'Dashboard Docentes',
    resource: 'alumno',
    show: [
      UserTypes.SCHOOLCONTROL_EMPLOYEE,
      UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
      UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
      UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
      UserTypes.KINDER_COORDINATOR_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
      UserTypes.TEACHER_EMPLOYEE,
    ],
  },
  {
    title: 'Dashboard',
    to: 'Dashboard Tutores',
    resource: 'alumno',
    show: [UserTypes.TUTOR],
  },
  {
    title: 'Alumnos',
    to: 'Alumnos',
    resource: 'alumno',
    show: [UserTypes.TUTOR, UserTypes.TEACHER_EMPLOYEE],
  },
  {
    title: 'Grupos',
    to: 'Grupos',
    resource: 'grupo',
    show: [UserTypes.TEACHER_EMPLOYEE],
  },
  {
    title: 'Finanzas',
    to: 'Ingresos',
    resource: 'ingreso',
    children: [
      {
        title: 'Ingresos',
        to: 'Ingresos',
        resource: 'ingreso',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      {
        title: 'Egresos',
        to: 'Egresos',
        resource: 'egreso',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      {
        title: 'Asignación de colegiaturas',
        to: 'Adeudos',
        resource: 'adeudo',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
    ],
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.APP_ADMIN_EMPLOYEE,
      UserTypes.ACCOUNTING_EMPLOYEE,
      UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
      UserTypes.COLLECTION_EMPLOYEE,
    ],
  },
  {
    title: 'Administración',
    to: 'Alumnos',
    resource: 'alumno',
    children: [
      {
        title: 'Acciones de grupos',
        to: 'Acciones Grupos',
        resource: 'cambiogrupo',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
        ],
      },
      {
        title: 'Alumnos',
        to: 'Alumnos',
        resource: 'alumno',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
          UserTypes.COLLECTION_EMPLOYEE,
        ],
      },
      {
        title: 'Empleados',
        to: 'Empleados',
        resource: 'empleado',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
        ],
      },
      {
        title: 'Proveedores',
        to: 'Proveedores',
        resource: 'proveedor',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
        ],
      },
      {
        title: 'Anuncios',
        to: 'Anuncios',
        resource: 'anuncios',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.SCHOOLCONTROL_EMPLOYEE,
          UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
          UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
          UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
          UserTypes.KINDER_COORDINATOR_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_EMPLOYEE,
          UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
          UserTypes.TEACHER_EMPLOYEE,
        ],
      },
      {
        title: 'Productos y servicios',
        to: 'Productos',
        resource: 'product',
        show: [
          UserTypes.SUPERADMIN,
          UserTypes.APP_ADMIN_EMPLOYEE,
          UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
          UserTypes.ACCOUNTING_EMPLOYEE,
        ],
      },
    ],
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.APP_ADMIN_EMPLOYEE,
      UserTypes.SCHOOLCONTROL_EMPLOYEE,
      UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
      UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
      UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
      UserTypes.KINDER_COORDINATOR_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_EMPLOYEE,
      UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
      UserTypes.ACCOUNTING_EMPLOYEE,
      UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
      UserTypes.TEACHER_EMPLOYEE,
      UserTypes.COLLECTION_EMPLOYEE,
    ],
  },
];

export const configMenu = [
  {
    title: 'Configuración',
    to: 'Configuracion',
    icon: true,
    show: [
      UserTypes.SUPERADMIN,
      UserTypes.APP_ADMIN_EMPLOYEE,
      UserTypes.SCHOOLCONTROL_EMPLOYEE,
      UserTypes.ACCOUNTING_EMPLOYEE,
    ],
  },
];
export const posMenu = [
  { title: 'Venta', to: 'Venta' },
  { title: 'Ordenes', to: 'Ordenes' },
  { title: 'Inventario', to: 'Inventario' },
];
