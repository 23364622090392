<template>
  <v-dialog
    v-model="showAlert"
    persistent
    max-width="390"
  >
    <v-card
      tile
      flat
      :class="borderColor"
    >
      <div
        v-if="loadingAlert"
        class="progressContainer text-center">
        <p>{{ alertText }}</p>
        <v-progress-circular
          indeterminate
          :color="progressColor"
        />
      </div>
      <v-card-text
        v-if="!loadingAlert"
        class="pt-15 text-h5 font-weight-bold text-center"
        :class="textColor"
      >
        {{ alertText }}
      </v-card-text>
      <v-card-actions
        v-if="!loadingAlert"
        class="pb-5">
        <v-btn
          outlined
          tile
          @click="setShowAlert(false)"
        >Cerrar</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          tile
          @click="setShowAlert(false)"
        >Agregar otro</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['showAlert', 'alertType', 'alertText', 'loadingAlert']),
    borderColor() {
      switch(this.alertType) {
        case 'success': return 'success_style'
        case 'error': return 'error_style'
        case 'info': return 'info_style'
        default: return 'info_style'
      }
    },
    textColor() {
      switch(this.alertType) {
        case 'success': return 'success--text'
        case 'error': return 'error--text'
        case 'info': return 'info--text'
        default: return 'info--text'
      }
    },
    progressColor() {
      switch(this.alertType) {
        case 'success': return 'success'
        case 'error': return 'error'
        case 'info': return 'info'
        default: return 'info'
      }
    }
  },
  methods: {
    ...mapMutations(['setShowAlert'])
  }
}
</script>
<style>
.v-card.success_style{
  border-color: #00AE42 !important;
  border-style: solid !important;
  border-width: medium !important;
}
.v-card.error_style{
  border-color: #FF5F00 !important;
  border-style: solid !important;
  border-width: medium !important;
}
.v-card.info_style{
  border-color: #136FEE !important;
  border-style: solid !important;
  border-width: medium !important;
}
.progressContainer {
  min-height: 100px;
  margin-top: 30px;
}
</style>